// @flow

import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './home.module.scss';

// $FlowFixMe
import 'react-responsive-modal/styles.css';

import Productivity from '../../assets/img/productivity.svg';
import OneWeek from '../../assets/img/one-week.svg';
import ReactIcon from '../../assets/img/react.svg';
import Docker from '../../assets/img/docker.svg';
import Sass from '../../assets/img/sass.svg';
import Css from '../../assets/img/css.svg';
import Nodejs from '../../assets/img/nodejs.svg';
import Html5 from '../../assets/img/html-5.svg';

import metaValues from '../../../config';
import translation from '../../translate';
import dictionary from './translate';

const scroll = Scroll.animateScroll;

const HomeScreen = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  const areaBlock = () => (
    <section className={styles.transparency}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.leftHalf}>
            <h1>{translation('_HOME_SECTION1a', language, dictionary)}</h1>
            <p>{translation('_HOME_SECTION1b', language, dictionary)}</p>
            <p>{translation('_HOME_SECTION1c', language, dictionary)}</p>
            <h3>{translation('_HOME_SECTION1d', language, dictionary)}</h3>
          </div>
          <div className={styles.rightHalf}>
            <img src={Productivity} alt="Productivity" />
            <div className={styles.buttonLink}>
              <Link
                to={`/${language}/how-we-work`}
                onClick={() => scroll.scrollToTop({ duration: 50 })}
              >
                <Button icon="Menu" size="large">
                  {translation('_HOME_SECTION1e', language, dictionary)}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const specialOffer = () => (
    <section className={styles.specialOffer}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.leftHalf}>
            <img src={OneWeek} alt="Special offer" />
          </div>
          <div className={styles.rightHalf}>
            <h1>{translation('_HOME_SECTION2a', language, dictionary)}</h1>
            <p>{translation('_HOME_SECTION2b', language, dictionary)}</p>
            <p>{translation('_HOME_SECTION2c', language, dictionary)}</p>
            <p>
              <b>{translation('_HOME_SECTION2d', language, dictionary)}</b>
            </p>
            <ul key="list1">
              <li key={11}>React/Redux</li>
              <li key={12}>MongoDB/SQL/PostgreSQL</li>
              <li key={13}>Flow/Typescript</li>
              <li key={14}>Node.js/Express</li>
            </ul>
            <h3>{translation('_HOME_SECTION2e', language, dictionary)}</h3>
          </div>
        </div>
      </div>
    </section>
  );

  const ourExpertise = () => (
    <section className={styles.ourExpertise}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.leftHalf}>
            <h1>{translation('_HOME_SECTION3', language, dictionary)}</h1>
            <p>{translation('_HOME_SECTION4', language, dictionary)}</p>
            <Link
              to={`/${language}/expertise`}
              onClick={() => scroll.scrollToTop({ duration: 100 })}
            >
              <Button>
                {translation('_HOME_SECTION5', language, dictionary)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );

  const featuresBlock = () => (
    <section className={styles.featuresBlock}>
      <div className={styles.content}>
        <h1>{translation('_HOME_SECTION6', language, dictionary)}</h1>
        <p>{translation('_HOME_SECTION7', language, dictionary)}</p>
        <p>{translation('_HOME_SECTION8', language, dictionary)}</p>
        <h3>{translation('_HOME_SECTION9', language, dictionary)}</h3>

        <div className={styles.grid}>
          <div>
            <img src={Html5} alt="Interactive Design" />
            <h4>Interactive Design</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
          <div>
            <img src={Css} alt="Latest Technology" />
            <h4>Latest Technology</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
          <div>
            <img src={Sass} alt="Interactive Design" />
            <h4>User Friendly</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
          <div>
            <img src={Nodejs} alt="Interactive Design" />
            <h4>Interactive Design</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
          <div>
            <img src={Docker} alt="Interactive Design" />
            <h4>Interactive Design</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
          <div>
            <img src={ReactIcon} alt="Interactive Design" />
            <h4>Interactive Design</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima
              animi blanditiis dolores quae hic ex asperiores maiores.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const subscribeBlock = () => (
    <section className={styles.subscribe}>
      <div className={styles.overlay} />
      <div className={styles.content}>
        <h1>Subscribe Our Newsletter For Update News</h1>
        <input type="text" name="email" placeholder="Enter Tour Email" />
        <Button>Subscribe Now</Button>
      </div>
    </section>
  );

  return (
    <section>
      <Helmet>
        <title>
          {translation('_SEO_TITLE1', language, dictionary)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content={translation('_SEO_TITLE2', language, dictionary)}
        />
        <meta
          property="keywords"
          content={translation('_SEO_TITLE3', language, dictionary)}
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/public/productivity.svg" />
      </Helmet>

      <section className={styles.welcomeArea}>
        <div className={styles.content}>
          <div className={styles.rocket} />
          <div className={styles.grid}>
            <div className={styles.leftHalf}>
              <h2>{translation('_HOME_TITLE1', language, dictionary)}</h2>
            </div>
            <div className={styles.rightHalf}>
              <h2>{translation('_HOME_TITLE2', language, dictionary)}</h2>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={styles.leftHalf}>
              <div className={styles.heading}>
                {translation('_HOME_TITLE3', language, dictionary)}
              </div>
            </div>
            <div className={styles.rightHalf}>
              <div className={styles.cloud}>
                {translation('_HOME_TITLE4', language, dictionary)}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Section className={styles.texts}>
        <div className={styles.content}>
          <div className={styles.grid}>
            <div className={styles.leftHalf}>
              <h1>{translation('_HOME_TITLE31', language, dictionary)}</h1>
            </div>
            <div className={styles.rightHalf}>
              <p>{translation('_HOME_TITLE32', language, dictionary)}</p>
            </div>
          </div>

          <div className={styles.grid}>
            <div className={styles.leftHalf}>
              <h1>{translation('_HOME_TITLE3', language, dictionary)}</h1>
              <p>{translation('_HOME_TITLE4', language, dictionary)}</p>
            </div>
            <div className={styles.rightHalf}>
              <p>{translation('_HOME_TEXT1', language, dictionary)}</p>
              <p>{translation('_HOME_TEXT2', language, dictionary)}</p>
            </div>
          </div>
        </div>
      </Section>

      {areaBlock()}
      {specialOffer()}
      {ourExpertise()}
      {featuresBlock()}
      {subscribeBlock()}
    </section>
  );
};

// $FlowFixMe
export default memo(HomeScreen);
